
import { Namespace } from '@/store/types'
import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

declare var consoleLog: any

@Component({})

export default class CallbackVue extends Vue {
  @Action('getTenantSetupJob', { namespace: Namespace.App })
  public getTenantSetupJob: any

  private isHandlingCallback: boolean = false

  protected async mounted() {
    if (this.$auth0.error) {
      let oopsErrorParam = window.location.search.substring(1)
      if (window.location.search.indexOf('error_description') === -1 && this.$auth0.error.message) { // If we have the rejection error description then show that.
        oopsErrorParam = `${oopsErrorParam}&error_message=${encodeURIComponent(this.$auth0.error.message)}`
      }
      this.$router.push(`/oops#${oopsErrorParam}`)
    }

    if (this.$auth0.isAuthenticated) {
      await this.handleAuthCallback()
    }
  }

  private async handleAuthCallback() {
    if (this.isHandlingCallback) {
      return
    }

    this.isHandlingCallback = true
    const redirectTo = this.$auth0.appState ? this.$auth0.appState.targetPath : '/my-jigs'
    const urlParams = new URLSearchParams(window.location.search)

    // If the user has just logged in, we need to check if there is a tenant-setup-job waiting for them to complete
    if (this.$auth0.user.email_verified && await this.getTenantSetupJob({ userEmail: this.$auth0.user.email })) {
      // This is the case if they have just recently verified their email address.
      if (urlParams.get('goingToWelcome') && (this.$store.state.app.tenantSetupJob && this.$store.state.app.tenantSetupJob.status === 'pending-payment')) {
        // If status is 'pending-payment' then take them to the 'tenant' page so they can address this
        consoleLog(`at callback handleLoginEvent, goingToWelcome(${urlParams.get('goingToWelcome')}) but pending-payment so redirecting to tenant so the user can fix this`)
        await this.$router.push(`/tenant`)
      } else {
        consoleLog(`at callback handleLoginEvent after getting tenant setup job, going to ${redirectTo || '/'}`)
        await this.$router.push(redirectTo || '/')
      }
    } else if ((this.$auth0.tenants == null || this.$auth0.tenants.length === 0)) {
      consoleLog(`at callback handleLoginEvent, going to /no-tenant`)
      await this.$router.push('/no-tenant')
    } else {
      consoleLog(`at callback handleLoginEvent, going to ${redirectTo || '/'}`)
      await this.$router.push(redirectTo || '/')
    }

    this.isHandlingCallback = false
  }
}
